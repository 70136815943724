import { Space } from 'antd';
import classNames from 'classnames';
import React from 'react';
import appStore from '../../assets/app-store-badge.svg';
import playStore from '../../assets/google-play-badge.svg';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { ScrollToTop } from '../../components/scroll_to_top/scroll_to_top';
import SvgDepthContourIcon from '../../components/svg/depthContourIcon';
import SvgLakes from '../../components/svg/lakes';
import SvgReports from '../../components/svg/reports';
import { useResponsive } from '../../hooks/use_responsive';
import styles from './app_page.less';
import graphic1 from './graphic1.png';
import graphic2 from './graphic2.png';
import phone from './phone.png';
import premProImage from './premium_pro_phones.png';

const AppPage = () => {
  const { isMobile, isDesktop, isSmallDesktop } = useResponsive();

  const titleText = (
    <h1 className={styles.h1}>
      <em>Omnia Fishing App</em>
      Data, gear, and expertise for your exact lake.
    </h1>
  );

  return (
    <ScrollToTop>
      <header className={styles.hero}>
        <ContentWrapper>
          {!isSmallDesktop && titleText}
          <div className={styles.heroContent}>
            <div className={styles.heroText}>
              {isSmallDesktop && titleText}
              <div className={styles.heroAppIcons}>
                <a href="https://omnia.fish/apple">
                  <img src={appStore} alt="Download on the App Store" width={177} />
                </a>
                <a href="https://omnia.fish/google">
                  <img src={playStore} alt="Get it on Google Play" width={200} />
                </a>
              </div>
            </div>
            <div className={styles.heroPhone}>
              {isDesktop && <div></div>}
              <img src={phone} alt="image of phone with temperature contours" />
            </div>
          </div>
        </ContentWrapper>
      </header>

      <section className={styles.stripe}>
        <ul className={styles.callouts}>
          <li className={styles.callout}>
            <SvgDepthContourIcon />
            Map layers for contours, weather, and more
          </li>
          <li className={styles.callout}>
            <SvgReports fontSize={'21px'} />
            Read and file fishing reports
          </li>
          <li className={styles.callout}>
            <SvgLakes fontSize={'21px'} />
            Products picked for your lake
          </li>
        </ul>
      </section>

      <ContentWrapper>
        <div className={classNames(styles.row, styles.rowOne)}>
          <div>
            <h2>The best local data</h2>
            <p>
              We’ve brought together information from satellites, weather sensors, sonar, and more
              to give you all you need to know about your lake in one app. Plus you can tap into
              fishing reports from thousands of local ambassadors who know the water best.
            </p>
          </div>
          <div className={styles.rowImg}>
            <img src={graphic2} alt="fishing report picture with lake name" />
          </div>
        </div>
        <div className={classNames(styles.row, styles.row__reverse, styles.rowTwo)}>
          <div>
            <h2>Access Omnia PRO Map Layers</h2>
            <p>
              Members can browse current lake conditions including depth contours, bottom hardness,
              water clarity, wind direction and more for thousands of lakes across the US. Free
              7-day trial.
            </p>
          </div>
          <div className={styles.rowImg}>
            <img src={premProImage} alt="phones showing PRO temperature maps" />
          </div>
        </div>

        <div className={classNames(styles.row, styles.rowThree)}>
          <div>
            <h2>Better tackle shopping experience</h2>
            <p>
              We've assembled a huge selection of fishing products and presented them in a way
              tailored to anglers. You can shop by lake or explore products by species or technique.
              The entire store has been infused with smart callouts based on local fishing report
              data so your most relevant items are always highlighted along the way. Our staff of
              experienced anglers are available through live chat or email for any questions or
              advice. We're fast too - orders in by 1pm Central ship the same day.
            </p>
          </div>
          <div className={styles.rowImg}>
            <img src={graphic1} alt="rows of product categories" style={{ marginTop: '10px' }} />
          </div>
        </div>
      </ContentWrapper>
      <section className={styles.download}>
        <ContentWrapper>
          <h2>Download the Omnia App on your phone</h2>
          <Space size="middle" align="center" direction={isMobile ? 'vertical' : 'horizontal'}>
            <a href="https://omnia.fish/apple">
              <img src={appStore} alt="Download on the App Store" width={177} />
            </a>
            <a href="https://omnia.fish/google">
              <img src={playStore} alt="Get it on Google Play" width={200} />
            </a>
          </Space>
        </ContentWrapper>
      </section>
    </ScrollToTop>
  );
};

export default AppPage;
