import * as React from 'react';
const SvgDepthContourIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      fill="#fff"
      d="M13.991 10.118a8.834 8.834 0 0 0-1.046-.548c-.14-.065-.28-.13-.417-.2-.094-.046-.19-.09-.285-.136-.165-.078-.33-.153-.483-.249-.702-.44-1.379-.631-2.073-.583a.9.9 0 0 0-.252.036c-1.402.415-1.8 1.578-1.976 2.417-.197.937.096 1.755.825 2.303.433.326.975.513 1.658.572.632.056 1.153.12 1.61.404.521.322.982.42 1.351.42.182 0 .342-.023.474-.056.554-.136 1.02-.5 1.346-1.053.7-1.184.386-2.613-.732-3.326Zm-.793 2.424c-.076.13-.165.215-.244.235-.105.027-.274-.03-.47-.15-.787-.487-1.578-.592-2.388-.663-.343-.03-.586-.102-.747-.223-.12-.09-.223-.198-.155-.52.147-.703.355-.939.654-1.054.3-.01.603.09.97.32.229.142.464.253.671.35.083.038.165.078.246.118.151.076.305.147.459.22a7.4 7.4 0 0 1 .844.437c.38.242.324.652.16.93Z"
    />
    <path
      fill="#fff"
      d="M16.108 8.15c-1.422-.902-3.035-1.923-4.652-2.851-.805-.463-1.768-.708-2.784-.71-.007-.002-.013 0-.02 0a.773.773 0 0 0-.198.016l-.19.036a6.086 6.086 0 0 0-.534.114c-1.019.277-1.78.997-2.142 2.03a59.699 59.699 0 0 0-1.397 4.502c-.42 1.584.266 3.061 1.83 3.952a50.17 50.17 0 0 0 3.927 1.99c.841.386 1.74.578 2.722.578.367 0 .746-.027 1.137-.08 1.11-.151 1.993-.47 2.774-.998 1.221-.827 1.561-2.046 1.71-2.89.138-.78.253-1.815-.076-2.896-.363-1.2-1.072-2.14-2.107-2.794Zm.438 5.382c-.152.86-.438 1.379-.958 1.73-.547.37-1.189.596-2.02.71-1.096.148-2.039.034-2.88-.353-1.487-.684-2.69-1.294-3.79-1.92-.872-.497-1.207-1.155-.994-1.958.366-1.38.81-2.81 1.357-4.37.167-.478.482-.783.933-.905.127-.035.257-.057.387-.08l.127-.024c.693.006 1.337.17 1.866.474 1.585.91 3.18 1.92 4.587 2.811.666.42 1.123 1.03 1.36 1.81.173.573.181 1.194.026 2.075Z"
    />
    <path
      fill="#fff"
      d="M21.83 12.54c-.123-2.241-1.074-4.104-2.748-5.386-1.856-1.422-3.81-2.763-5.699-4.06l-.99-.68C10.754 1.287 8.89 1.19 7.617 1.213H7.61a.844.844 0 0 0-.176.012c-.132.022-.265.041-.396.061-.315.047-.641.095-.973.174-2.14.508-3.303 1.967-3.365 4.22-.036 1.279-.336 2.566-.917 3.935-.687 1.618-1.323 3.214-1.56 4.93-.09.654-.258 1.871.555 2.864.767.935 1.922.957 2.414.967 1.314.024 2.603.358 3.94 1.018 1.687.831 3.173 1.243 4.678 1.292.267.01.534.028.802.047.394.028.797.056 1.21.056.116 0 .234-.002.35-.008 2.324-.1 4.26-.704 5.921-1.845.945-.648 1.523-1.654 1.628-2.83.093-1.053.178-2.3.108-3.567l.001.002Zm-1.874 3.41c-.058.649-.357 1.178-.865 1.527-1.38.947-3.013 1.448-4.995 1.535-.434.018-.883-.013-1.36-.046-.288-.02-.577-.04-.867-.05-1.244-.041-2.5-.395-3.953-1.111-1.576-.777-3.11-1.17-4.69-1.2-.604-.012-.897-.098-1.078-.319-.27-.331-.269-.782-.17-1.498.207-1.503.773-2.918 1.436-4.48.66-1.555 1.015-3.095 1.057-4.578.04-1.46.64-2.222 2.003-2.545.27-.064.548-.104.824-.145.118-.017.237-.036.355-.055 1.627-.022 2.795.243 3.735.89l.992.68c1.871 1.285 3.805 2.611 5.624 4.007 1.25.957 1.962 2.368 2.056 4.077.064 1.156-.017 2.323-.104 3.312v-.001Z"
    />
  </svg>
);
export default SvgDepthContourIcon;
